import React from 'react';

import maatwerkImage1 from 'assets/images/pages/diensten_maatwerk/1.jpg';
import maatwerkImage2 from 'assets/images/pages/diensten_maatwerk/2.jpg';
import maatwerkImage3 from 'assets/images/pages/diensten_maatwerk/3.jpg';

import Title from 'blocks/Title';
import Image from 'blocks/Image';
import ContactCta from 'blocks/ContactCta';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Section from 'components/Section';
import Heading from 'components/Heading';
import Text from 'components/Text';
import Button from 'components/Button';

const DienstenBouwenMaatwerk = () => (
  <Layout>
    <Seo image={{ src: maatwerkImage1 }} />

    <Title
      image={{ src: maatwerkImage1 }}
      alt="Webshop maatwerk bij Batao"
      subtitle="Magento webshops en toepassingen op maat"
      title="Webshop maatwerk bij Batao"
    >
      Geen standaard webwinkel maar een webshop die qua uiterlijk en
      functionaliteit volledig is aangepast aan de behoeften van jouw
      organisatie? Voor het beste webshop maatwerk kun je terecht bij Batao.
    </Title>

    <Section>
      <Image image={{ src: maatwerkImage2 }} alt="Intensieve samenwerking">
        <Heading size="xl" as="h2">
          Intensieve samenwerking met korte communicatielijntjes
        </Heading>

        <Text>
          Wij vinden een intensieve samenwerking met korte communicatielijntjes
          één van de belangrijkste onderdelen van een vruchtbare samenwerking.
          Dat is dan ook de reden dat we ervoor kiezen om alles wat we maken,
          intern te doen. Wij werken niet met outsourcing naar India of het
          oostblok, maar ontwikkelen alles gewoon zelf vanuit ons kantoor in
          Nijmegen. Snelle communicatie met de beste developers zorgt ervoor dat
          we jouw wensen constant op de eerste plek kunnen zetten, waardoor we
          altijd tot het beste resultaat kunnen komen voor jouw business.
        </Text>
      </Image>
    </Section>

    <Section>
      <Image
        image={{ src: maatwerkImage3 }}
        alt="De bewezen developers van Batao"
        position="right"
      >
        <Heading size="xl" as="h2">
          De bewezen developers van Batao
        </Heading>

        <Text spacing="lg">
          Wij kennen Magento door en door. Niet alleen omdat we te gekke
          webshops bouwen, maar ook omdat we revolutionaire B2B modules bouwen
          zoals het hoog beoordeelde Cart2Quote. Zeker wanneer je organisatie
          behoefte heeft aan webshop maatwerk, is deze ervaring goud waard. We
          weten hoe we complexe functionaliteit kunnen inbouwen, en zijn altijd
          kritisch op de geleverde kwaliteit. Kortom komt het er op neer dat het
          niet uitmaakt wat er gebouwd moet worden, Batao klaart de klus!
        </Text>

        <Button color="tertiary" to="/contact">
          Laat je webshop door ons bouwen
        </Button>
      </Image>
    </Section>

    <ContactCta />
  </Layout>
);

export default DienstenBouwenMaatwerk;
